import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import ButtonNew from './ButtonNew';
import Dialog from './Dialog';
import { ErrorPanel } from './components/form/Form';
import { ReactChildren } from './utils';

type Props = {
    title?: string;
    children?: ReactChildren;
    error?: unknown;
    onClose: () => void;
};

export default function ErrorDialog({ title = 'Error', children, error, onClose }: Props) {
    return (
        <Dialog
            title={title}
            titleIcon={<ExclamationTriangleIcon className="h-8 w-8 text-error-600" />}
            dialogSize="sm"
            resetDialog={onClose}
        >
            <>
                {children}
                <ErrorPanel error={error} />
                <div className="mt-8 flex items-center gap-4">
                    <ButtonNew buttonStyle="primary" onClick={onClose}>
                        Ok
                    </ButtonNew>
                </div>
            </>
        </Dialog>
    );
}
