import React, { Fragment, useRef, useState } from 'react';
import { Dialog as ReactDialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ReactChildren } from './utils';

export const DIALOG_SIZES = {
    sm: 'sm',
    lg: 'lg',
};

export type DialogSize = keyof typeof DIALOG_SIZES;

type Props = {
    title: string;
    titleIcon?: React.JSX.Element;
    resetDialog: () => void;
    children: ReactChildren;
    overflowVisible?: boolean;
    dialogSize?: DialogSize;
};

export default function Dialog({ title, resetDialog, titleIcon, children, overflowVisible, dialogSize = 'sm' }: Props) {
    const [open, setOpen] = useState(true); // eslint-disable-line @typescript-eslint/no-unused-vars

    const onClose = () => {
        resetDialog();
    };

    const cancelButtonRef = useRef(null);

    const overflowCssClass = overflowVisible ? ' overflow-visible' : 'overflow-hidden';

    const sizeVariants = {
        [DIALOG_SIZES.sm]: 'md:max-w-none md:w-[400px]',
        [DIALOG_SIZES.lg]: 'md:max-w-none md:w-[640px]',
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <ReactDialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <ReactDialog.Panel
                                className={`relative transform rounded-sm bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 ${sizeVariants[dialogSize]} ${overflowCssClass}`}
                            >
                                <button
                                    type="button"
                                    className="absolute right-5 top-5 text-black"
                                    onClick={onClose}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-[18px] w-[18px]" aria-hidden="true" />
                                </button>
                                <div className="sm:flex-auto sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <div className="mr-10 flex justify-between">
                                            <ReactDialog.Title as="h3">
                                                <span className="flex flex-row items-center gap-2 break-normal">
                                                    {titleIcon}
                                                    {title}
                                                </span>
                                            </ReactDialog.Title>
                                        </div>
                                        <div className="mt-2">{children}</div>
                                    </div>
                                </div>
                            </ReactDialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </ReactDialog>
        </Transition.Root>
    );
}
